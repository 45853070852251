import {UserMunicipality} from "@shared/models/user-municipality.model";

export const MossMunicipality: UserMunicipality = {
  name: 'moss',
  slogan: {value: '', id: 'banner.moss', meaning: 'bannerSlogan', description: 'slogan for moss'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: false,
  hasTickets: true
}
